<template>
  <div class="money-detail">
    <div class="m-header">
      <div class="m-icon" @click="toHome">
        <span class="iconfont icon-left"></span>
      </div>
      <div class="m-title">红包明细</div>
    </div>
    <div v-for="(item, index) in redDetail" :key="index" class="m-main">
      <div>
        <p v-if="item.status == 1 && item.type == 'receive'" class="title">{{ item.activity_rule_type | filterRulesType }}（领取成功）</p>
        <p v-if="item.status == 2 && item.type == 'receive'" class="title">{{ item.activity_rule_type | filterRulesType }}（领取失败）</p>
        <p v-if="item.status == 1 && item.type == 'withdraw'" class="title">{{ item.activity_rule_type | filterRulesType }}（提现审核中）</p>
        <p v-if="item.status == 2 && item.type == 'withdraw'" class="title">{{ item.activity_rule_type | filterRulesType }}（提现中）</p>
        <p v-if="item.status == 3 && item.type == 'withdraw'" class="title">{{ item.activity_rule_type | filterRulesType }}（提现成功）</p>
        <p v-if="item.status == 4 && item.type == 'withdraw'" class="title">{{ item.activity_rule_type | filterRulesType }}（提现失败）</p>
        <p v-if="item.status == 5 && item.type == 'withdraw'" class="title">{{ item.activity_rule_type | filterRulesType }}（审核不通过）</p>
        <p class="time">{{ item.updated_at }}</p>
      </div>
      <div class="money-value">
        <p v-if="item.status == 1 && item.type == 'receive'" class="add">+{{ item.red_package_amount }}</p>
        <p v-if="item.status == 1 && item.type == 'withdraw'" class="wait">{{ item.withdraw_amount }}</p>
        <p v-if="item.status == 2 && item.type == 'withdraw'" class="less">{{ item.withdraw_amount }}</p>
        <p v-if="item.status == 3 && item.type == 'withdraw'" class="less">-{{ item.withdraw_amount }}</p>
        <!-- <p></p> -->
        <!-- <p class="add">{{item.withdraw_amount}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getRedPackageList } from '@/api/redList';
export default {
  name: 'MoneyDetail',
  filters: {
    filterRulesType(type) {
      console.log('XXXX', type);
      if (type === 'level') {
        return '等级红包';
      } else if (type === 'repay') {
        return '充返红包';
      } else if (type === 'pay') {
        return '充值红包';
      } else if (type === 'login') {
        return '登录';
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      moneyValue: [],
      currentIndex: 0,
      redDetail: [],
    };
  },
  activated() {
    // this.$bus.$on("getRedPackageList", this.getRedPackageList)
    getRedPackageList({
      app_id: window.sessionStorage.getItem('appid'),
      token: window.sessionStorage.getItem('ato'),
      limit: 100,
    }).then((res) => {
      if (res.data.status === 200) {
        this.redDetail = res.data.data;
      } else {
        this.$toast(res.data.msg);
      }
    });
  },
  methods: {
    toHome() {
      // this.$router.back()
      this.$router.push('/home');
    },
    selectMoney(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.money-detail {
  .m-header {
    position: relative;
    background-color: #f6f6f6;

    height: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    .m-icon {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      .icon-left {
        font-size: 0.34rem;
        color: #333;
        margin-left: 0.3rem;
      }
    }
    .m-title {
      flex: 1;
      font-size: 0.32rem;
    }
  }
  .m-main {
    // border-top: 0.02rem solid #ebedf0;
    margin-top: 0.24rem;
    padding: 0 0.3rem;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .title {
      font-size: 0.34rem;
      font-weight: 700;
    }
    .time {
      color: #666666;
      font-size: 0.28rem;
      margin-top: 0.12rem;
    }
    .money-value {
      font-size: 0.34rem;
      // font-weight: 700;
      .add {
        font-weight: 500;
        color: red;
      }
      .wait {
        font-weight: 500;
        color: #ccc;
      }
      .less {
        font-weight: 500;
        color: #89db5a;
      }
    }
  }
}
</style>
